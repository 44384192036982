export default defineNuxtRouteMiddleware(async (to, from) => {
    if (process.client) {
        const { data } = useAuthState()
        const router = useRouter()
        
        if (data.value
            && (!data.value.first_name || !data.value.last_name || !data.value.email || !data.value.email || !data.value.role_client
                || (!data.value.link_vk && !data.value.link_instagram && !data.value.other_link)
            ) && to.path !== '/profile') {
            router.push('/profile')
        }
    }
})
