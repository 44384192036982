import revive_payload_client_4sVQNw7RlN from "/var/frents-frontend-client/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/frents-frontend-client/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/frents-frontend-client/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/frents-frontend-client/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/frents-frontend-client/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/frents-frontend-client/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/frents-frontend-client/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/var/frents-frontend-client/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/frents-frontend-client/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/var/frents-frontend-client/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/var/frents-frontend-client/.nuxt/sentry-client-config.mjs";
import plugin_ucMK8VhJjR from "/var/frents-frontend-client/node_modules/nuxt-easy-lightbox/dist/runtime/plugin.mjs";
import plugin_I4dbrL2rYz from "/var/frents-frontend-client/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import slideovers_LDumGYo2KH from "/var/frents-frontend-client/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_bidRKewKK5 from "/var/frents-frontend-client/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "/var/frents-frontend-client/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_OVoKJro5pc from "/var/frents-frontend-client/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/var/frents-frontend-client/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "/var/frents-frontend-client/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import vue_mask_G0kaOxPqAb from "/var/frents-frontend-client/plugins/vue-mask.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_ucMK8VhJjR,
  plugin_I4dbrL2rYz,
  slideovers_LDumGYo2KH,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_OVoKJro5pc,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i,
  vue_mask_G0kaOxPqAb
]